.card-slider {
    width: 85%;
/* border-radius: 1rem; */
padding: 1rem;
box-shadow: 0 0 5px #888888;
margin: 0 auto;
background-color: #fff;
width: 100%;
border-radius: 10px;

}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.slider-item{
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    justify-content: center;

}


.slider-item .white-card{
  height: 200px;
  width: 200px;
  box-shadow: 0 0 0.3rem #797979;
  margin: 0 auto;
  background-color: #fff;
  display: flex;align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.slider-item .white-card:hover h3{
  color: #fff;
}
.slider-item .white-card:hover{
  background-color: #ff08ff;
  
}

@media screen and (max-width:800px) {
  .slider-item .white-card{
    margin: 0 auto;
  }
}


