#course-top {
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
  height: 50rem;
  display: flex;
  align-items: center;
}
#course-top::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}
.mern-course-top {
  background-image: url(../../../../../public/assets/tech.jpg);
}
.java-course-top {
  background-image: url(../../../../../public/assets/contact.jpg);
}
.aiml-course-top {
  background-image: url(../../../../../public/assets/service/training.jpg);
}
/* .python-course-top{
  background-image: url(../../../../../public/assets/service/);
} */

#course-top .course-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-top {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 90%;
  padding: 2rem 3rem 0 3rem;
  margin: 0 auto;
  border-radius: 1rem;
}
.course-top .course-top-left {
  width: 30%;
}
.course-top .course-top-left h1 {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.course-top .course-top-left h3 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  /* font-family: 'Rosarivo', serif; */
  font-family: "Playpen Sans", cursive;
}
.course-top .course-top-left .cta {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: #ff6a6a;
  text-transform: capitalize;
}
.course-top .course-top-left .cta:hover {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: transparent;
}
.course-top .course-top-right {
  width: 70%;
}
.course-top .course-top-right .slider-item .card3 {
  width: 97%;
  height: fit-content;
}
.course-top .slick-prev:before,
.course-top .slick-next:before {
  color: white;
}

#course-top-mid .top-mid {
  display: flex;
  justify-content: space-evenly;
}
.course-topCard {
  background-color: #fff;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.course-topCard h1 {
  color: black;
  font-size: 1.7rem;
}
.course-topCard p {
  font-family: "Playpen Sans", cursive;
  color: black;
  font-size: 1.5rem;
}

/* Course Overview start */
.course-overview-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.course-overview-left {
  width: 60%;
}
.course-overview-left p {
  font-size: 1.5rem;
  font-weight: normal;
}
.course-overview-left ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.course-overview-left ul li {
  width: 40%;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.course-overview-left ul li svg {
  /* color: #a2fffc; */
  color: #0b95ff;
  font-size: 3rem;
  margin-right: 0.5rem;
}
.course-overview-right {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}
.course-overview-right section {
  padding: 0;
}

/*  */
/*  */
.certificate-section {
  max-width: 90vw;
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  box-shadow: 5px 5px 20px #6d6b6b6b;
  margin: 1rem auto 5rem auto;
  background-color: #f4f9ff;
  border-top: 2px solid #6afff8;
}
.certificate-section:hover {
  border-top: 2px solid #ff6a6a;
}
.certificate-section .content {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 5rem;
}
.certificate-section .content-left {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
.certificate-section .content-left p {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.certificate-section .content-left ul {
  padding-left: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.certificate-section .content-left ul li {
  width: 50%;
  padding-right: 2rem;
  margin-bottom: 1rem;
  list-style-type: disc;
  font-size: 1.8rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #575757;
}
.certificate-section .content-left ul li::marker {
  font-size: 2rem;
  color: #0b95ff;
}

.certificate-section .content-right {
display: flex;
align-items: center;
justify-content: center;
}
.certificate-section .content-right img {
  width: 100%;
  height: 50rem;
  border-radius: 1rem;
}
/* =======================CERTIFICATE section END ===================== */

/*  */

.course-AccordionComp {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0 auto;
}
.course-AccordionComp-left {
  width: 40%;
}
.course-AccordionComp-right {
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.course-AccordionComp-right .simplecard {
  width: 70%;
  height: fit-content;
  margin-top: 10rem;
  position: sticky;
  top: 15rem;
  border-radius: 1rem;
  border-radius: 0;
  box-shadow: 0 0 20px #6d6b6b6b;
}
.course-AccordionComp-right .simplecard-title {
  padding: 1rem;
  color: white;
  background-color: rgb(9, 118, 161);
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}
.course-AccordionComp-right .simplecard-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-AccordionComp-right .simplecard-desc {
  padding: 1rem;
  background-color: #fff;
}
.course-AccordionComp-right .simplecard-desc .row {
  gap: 2rem;
  padding: 1rem;
}
.course-AccordionComp-right .simplecard-desc .row .icon {
  text-align: center;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-AccordionComp-right .simplecard-desc .row .icon svg {
  font-size: 3rem;
}
.course-AccordionComp-right .simplecard-desc .row .text {
  font-family: "Barlow Semi Condensed", sans-serif;
  width: 85%;
  font-size: 1.8rem;
}

.skills-covered li {
  list-style-type: disc;
  font-size: 1.5rem;
  font-weight: bold;
  width: 30%;
}
.skills-covered li::marker {
  color: rgb(21, 189, 255);
  font-size: 2rem;
}

.technologies-weuse-container img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: fit-content;
}

/* ============================================== Responsiveness ============================================== */

@media screen and (max-width: 1250px) {
  #course-top {
    height: 90rem;
  }
  .course-top {
    flex-direction: column;
  }
  .course-top .course-top-left,
  .course-top .course-top-right {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .course-overview-container {
    flex-direction: column;
    gap: 10rem;
  }
  .course-overview-left,
  .course-overview-right {
    width: 100%;
  }
  .certificate-section  {
    padding: 0.5rem;
  }
  .certificate-section .content {
    flex-direction: column-reverse;
  }
  .certificate-section .content-left,
  .certificate-section .content-right{
    width: 100%;
  }
  .certificate-section .content-right img {
    width: 100%;
    height: fit-content;
  }
  .course-AccordionComp{
    flex-direction: column;

  }
  .course-AccordionComp-left,
  .course-AccordionComp-right {
    width: 100%;
  }
   
}

@media screen and (max-width: 700px) {
  .top-mid {
    flex-direction: column;
  }
  .br3-orange {
    border-right: none;
    border-bottom: 3px solid #f26005;
  }
  .certificate-section{
    box-shadow: 0 0 5px rgb(169, 169, 169);

  }
  .certificate-section .content-left ul li {
    width: 100%;

  }
  .course-AccordionComp-right .simplecard{
    width: 100%;
  }
  .testimonial-section >.row{
    flex-direction: column ;
  }
}
@media screen and (max-width: 400px) {
  #course-top {
    height: 100rem;
  }
  .course-top{
    padding: 0.5rem;
  }
  .top-mid {
    flex-direction: column;
  }
  .br3-orange {
    border-right: none;
    border-bottom: 3px solid #f26005;
  }
}
