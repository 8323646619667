.education-top #top-section {
  background-image: url(../../../../public/assets/service/education/top.jpg);
}
.education-top #top-section::after {
opacity: 0.6;
}

.what-weoffer-container img {
  border-radius: 1rem;
  width: 33%;
}
.what-weoffer-container img:hover {
  transform: scale(1.02);
  transition: all 0.2s linear;
}

.education-cards .card2-item {
  width: 100%;
}

.rural-container img {
  width: 100%;
  border-radius: 1rem;
}

@media screen and (max-width: 800px) {
  .what-weoffer-container .row img {
    width: 100%;
  }
  .what-weoffer-container .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
