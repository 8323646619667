#top-section {
    background-image: url(../../../../public/assets/service/web.jpg);
    background-size: cover;
    background-position: top center;
    position: relative;
    z-index: 1;
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #top-section::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: -1;
  }
  
  #top-section .top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .top-section h1 {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
    text-align: center;

  }
  .top-section p {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;

  }
  .top-section .cta {
    color: white;
  width: fit-content;
  font-size: 2rem;
  text-transform: capitalize;
  background-color: crimson;
  }
  .top-section .cta:hover {
  color: white;
  width: fit-content;
  font-size: 2rem;
  text-transform: capitalize;
  background-color: transparent;
  }

  @media screen and (max-width:800px) {
    .top-section h1 {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }
    .top-section p {
      font-size: 1.2rem;
    }
    .top-section .cta {
    font-size: 1.5rem;
padding: 1rem;
    }
    
  }
  