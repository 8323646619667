.accordion-summary{

}
.accordion-summary h4 ul li{
    list-style-type: disc;
}
.accordion-summary h4 ul li::marker{
    color: #00cbcb;
}
.accordion-summary h4 ul li h4{
    font-size: 1.5rem;
    font-weight: 600;
}