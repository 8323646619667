.itstaff-top #top-section {
  background-image: url(../../../../public/assets/service/itstaff/top.jpg);
}
.itstaff-top #top-section::after {
opacity: 0.6;
}

/* it-staffing-services Start */
.it-staffing-services .card2-item{
  width: 100%;
}
.it-staffing-services .card2-item .card2-info h1{
  margin-bottom: 0;
}
.it-staffing-services .card2-item .card2-info p{
  font-size: 1.5rem;
}
.it-staffing-services .card2-item .card2-info h4{
  font-size: 1.5rem;
  margin-top: 0.3rem;

}
/* it-staffing-services END */