.portfolio-top-img{
    width: 100%;
}
.portfolio-section .work {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 2px rgb(190, 190, 190);
}

.portfolio-section .work img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    display: block;
    transition: transform 0.5s;
}

.portfolio-section .layer {
    width: 100%;
    height: 0;
    background: rgb(211, 211, 211); 
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    font-size: 16px;
    transition: height 0.5s;
}

.portfolio-section .layer h2 {
    margin-bottom: 10px;
    color: #000000;
    font-family: "Josefin Sans", sans-serif;


}

.portfolio-section .layer a {
    margin-top: 10px;
    color: black;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
}


.portfolio-section .work:hover img {
    transform: scale(1.1);
}

.portfolio-section .work:hover .layer {
    height: 100%;
}