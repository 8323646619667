a {
  text-decoration: none;
}
.simplecard {
  text-decoration: none;
  color: #000000;
}

.simplecard-title {
  font-weight: 500;
  color: #dce2f0;
  /* background-color: #b2c2bf ; */
  background-color: #50586c;
}
.simplecard-desc {
  /* background-color: #c0ded9 ; */
  background-color: #dce2f0;
}
.image-card {
  box-shadow: none !important;
}
.imageCardBody {
  position: absolute;
  bottom: 0;
}

.image-card:hover {
  box-shadow: 0 0 0.5rem #35353574 !important;
}

.simpleCard:hover {
  /* background-color: #9aaa8f !important; */
  box-shadow: 0 0 1rem #3535357c;
  transform: scale(1.02);
}

.adCard img {
  width: 10vw;
}

.tabcard-desc {
  text-align: justify;
}

/* =============================  HoverCard Start ================================= */
.hover-card {
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0 1rem 1rem 0;
}
.hover-card::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: -1;
  border-radius: 0 1rem 1rem 0;
}

.default-bg {
  background-image: url(../../../public/assets/service/web.jpg);
}
.health-bg {
  background-image: url(../../../public/assets/areas/health2.jpg);
}
.ecom-bg {
  background-image: url(../../../public/assets/areas/ecom2.jpg);
}
.edu-bg {
  background-image: url(../../../public/assets/areas/edu2.jpg);
}
.realestate-bg {
  background-image: url(../../../public/assets/areas/realestate2.jpg);
}
.media-bg {
  background-image: url(../../../public/assets/areas/media2.jpg);
}
.logistics-bg {
  background-image: url(../../../public/assets/areas/logistics2.jpg);
}
.tourism-bg {
  background-image: url(../../../public/assets/areas/tourism2.jpg);
}

.hover-card h1 {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  text-align: center;
}
.hover-card p {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}
.hover-card .cta {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  width: fit-content;
  font-size: 1.5rem;
  text-transform: capitalize;
  background-color: crimson;
}
.hover-card .cta:hover {
  color: white;
  width: fit-content;
  text-transform: capitalize;
  background-color: transparent;
}

/* =============================  HoverCard End ================================= */
/* =============================  TestimonialCard Start ================================= */
.eachdiv {
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  box-shadow: 5px 5px 20px #6d6b6b6b;
  color: white;
  border-top: 3px solid #0060fb;
}
.eachdiv:hover{
  border-top: 3px solid #fb5400;

}
.div4 {
  background: white;
  color: black;
}
.userdetails {
  display: flex;
}
.imgbox {
  margin-right: 1rem;
}
.imgbox img {
  border-radius: 50%;
  width: 8rem;
  border: 2px solid #cec5c5;
}
.detbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detbox p {
  margin: 0;
}
.detbox .name {
  color: #49505a;
  font-size: 2rem;
  margin-bottom: 0.1rem;
  font-weight: 600;
}

.detbox .designation {
  color: #49505a;
  opacity: 70%;
  font-size: 1.5rem;
}

.review h4 {
  font-size: 1.4rem;
  color: #4b5258;  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}
.review p {
  font-size: 1.5rem;
  color: #0e0e0e;
  font-weight: 500;
  opacity: 50%;
  line-height: 1.5;
}
.attribution {
  font-size: 1rem;
  line-height: 1.5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}
.attribution a {
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .innerdiv {
    transform: scale(0.7);
  }
}
@media only screen and (max-width: 800px) {
  .innerdiv {
    transform: scale(0.6);
  }
}
@media only screen and (max-width: 600px) {
  .div1 {
    background-position-x: 10rem;
  }
  .innerdiv {
    display: flex;
    flex-direction: column;
    transform: scale(1);
    margin: 2rem;
    margin-bottom: 5rem;
  }
  .attribution {
    position: relative;
  }
}
/* =============================  TestimonialCard End ================================= */

@media screen and (max-width: 800px) {
  .hover-card,
  .hover-card::after {
    border-radius: 0 0 1rem 1rem;
  }
}
@media screen and (max-width: 500px) {
  .tab-card {
    display: flex;
    flex-direction: column;
  }
  .tabcard-image {
    width: 100% !important;
  }
  .tabcard-desc-box {
    width: 100% !important;
  }
}
