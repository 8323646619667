@import "https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap";
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

/* Hero Section */
#training-top {
  background-image: url(../../../../public/assets/background.jpg);
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
  height: 40rem;
}
#training-top::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

#training-top .training-top {
  display: flex;
  align-items: center;
}
.training-main{
  margin-top: -13rem;
}

/* Course section Start */

#top-mid {
  position: relative;
  top: -9rem;
  width: 84.2vw;
  margin: 0 auto;
  margin-bottom: -12rem;
  z-index: 5;
  /* background-color: #fff; */
}
.top-mid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px #505050;
}
.top-mid-title {
  font-family: 'Josefin Sans', sans-serif;
  color: black;
  font-size: 2.5rem;
  font-weight: 500;
  padding: 1rem;
  border-radius: 1rem;
}

.course-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.course-item {
  /* padding: 1rem; */
  height: 150px ;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #9c9c9ca2;
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem #4b4b4bab;
}

.course-item:hover{
  box-shadow: 0 0 1rem #4b4b4bab;

}

.course-icon {
  transform: scale(4);
  margin-bottom: 2rem;
  color: #fff;

}

.course-item p {
  font-family: 'Josefin Sans', sans-serif;
  color: rgb(255, 255, 255);
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0 2rem;
}

/* Course section End */


/* Our Leading Partners  Start*/

.partners-training-section {
  width: 100%;
  max-width: 100vw;
}
.partners-training-section h4 {
  margin-bottom: 2rem;
}


.partners-training-section .training-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.partners-training-section .training-img-container > img {
  width: 20rem;
}
/* Our Leading Partners End*/

/* training-programs-container Start */

.training-programs-container {
  padding: 2rem;
  max-width: 90vw;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem #4b4a4a;
}
.training-programs-container .content {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.training-programs-container .content-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2rem;
}
.training-programs-container .content-left h3 {
  font-size: 2.5rem;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
}
.training-programs-container .content-right {
  width: 50%;
}
.training-programs-container .content-right img {
  width: 100%;
  border-radius: 1rem;
}
/* training-programs-container End */

/* training-pg-certifications-container Start */
.training-pg-certifications-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.training-pg-certifications-container ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.training-pg-certifications-container ul li {
  font-size: 1.8rem;
  font-weight: normal;
  display: flex;
  align-items: center;
}
.training-pg-certifications-container ul li svg {
  color: rgb(255, 0, 221);
  font-size: 1.3rem;
  margin-right: 0.5rem;
}
.training-pg-certifications-container .training-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
}
.training-pg-certifications-container .card3 {
  width: 33%;
  height: 550px;
}
.training-pg-certifications-container .card3 .card3-content p{
  font-size: 1.35rem;
font-weight: 500;
}

/* training-pg-certifications-container End */

/* training-certificate-programs-container Start */
.training-certificate-programs-container .simplecard{
  width: 300px;
  height: 300px;
  box-shadow: 0 0 5px #6464646b;
}
.training-certificate-programs-container .simplecard:hover{
  box-shadow: 0 0 5px #35353591;
}
.training-certificate-programs-container .simplecard-title{
  height: 5rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Josefin Sans", sans-serif;
}
.training-certificate-programs-container .simplecard-desc{
  height: 100%;
  padding: 1rem;
  font-size: 1.5rem;
}

/* training-certificate-programs-container End */


/* training-hiring-partners-container Start */
.training-hiring-partners-container{
  display: flex;
  flex-direction: row;

}
.training-hiring-partners-left{
  width: 50%;
}
.training-hiring-partners-left h3{
  font-size: 2rem;
  color: #6e6e6e;
}
.training-hiring-partners-right{
  width: 50%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
/* training-hiring-partners-container End */

/* training-alumni-talk-container Start */
.training-alumni-talk-container{
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-left : auto;
  margin-right : auto;
}

.training-alumni-talk-left{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.training-alumni-talk-left-item{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
.training-alumni-talk-left-item > img{
  width: 30%;
}
.training-alumni-talk-left-item > span{
font-size: 3.5rem;
font-weight: 600;
font-family: "Josefin Sans", sans-serif;
display: flex;
align-items: center;
}
.training-alumni-talk-left-item > span::before{
content: '';
height: 3.5rem;
width: 2px;
background-color: #919191;
margin-right: 3rem;
}
.training-alumni-talk-left-item > span > svg{
font-size: 1rem;
color: #ffae00;
}
.training-alumni-talk-right{
  width: 50%;
}
/* training-alumni-talk-container End */

@media screen and (max-width:800px) {

  .cardslider {
    flex-direction: column;
  }
  .course-item {
    width: 120px;
  }
  .training-programs-container .content {
    flex-direction: column-reverse;
  }

  .training-programs-container .content-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 2rem;
  }
  .training-programs-container .content-left h3 {
    font-size: 2.5rem;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
  }
  .training-programs-container .content-right {
    width: 100%;
  }
  .training-programs-container .content-right img {
    width: 100%;
    border-radius: 1rem;
  }
  .partners-training-section .training-img-container > img {
    width: 10rem;
  }
  .training-pg-certifications-container .training-cards {
    flex-direction: column;
  }
  .training-pg-certifications-container .card3 {
    width: 100%;
    height: fit-content;
  }
  .training-certificate-programs-cards {
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
  .training-alumni-talk-container{
    flex-direction: column;
  }
  .training-alumni-talk-right,
  .training-alumni-talk-left
  {
    width: 100vw;
    margin: 0;
    }
    .training-alumni-talk-left{
      display: flex;
      align-items: center;
      justify-content: center;
    }
}