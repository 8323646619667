.software-out-top #top-section {
  background-image: url(../../../../public/assets/service/swout/top.jpg);

}
.software-out-top #top-section::after {
opacity: 0.6;
}

.what-weoffer-container img {
  border-radius: 1rem;
  width: 33%;
}
.what-weoffer-container img:hover {
  transform: scale(1.02);
  transition: all 0.2s linear;
}

/*  */

/*  */
.what-we-offer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}

/*  */

.technology-stack {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (max-width: 1100px) {
  .what-we-offer .row,
  .technology-stack .row {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .what-we-offer .row,
  .technology-stack .row {
    flex-direction: column;
  }
}
