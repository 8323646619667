.hoverComponent-container{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 400px;
    width: 80vw;
    box-shadow: 0 0 0.3rem #acacac;
    padding: 1rem;
    margin: 0 auto;
}
.hoverComponent-left{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
    background-color: rgb(219, 219, 219);

}
.hoverComponent-left li{
    font-family: "Josefin Sans", sans-serif;
    padding: auto 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px dotted black;
    font-size: 2rem;
    cursor:default;
}

.hoverComponent-left li:nth-last-child(1){
    border: none;
}
.hoverComponent-left li:hover{
    background-color: rgb(255, 5, 255);
}
.hoverComponent-right{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
}


@media screen and (max-width:800px) {
    .hoverComponent-container{
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem;
        border-radius: 1rem;
    }
    .hoverComponent-left{
        flex-direction: row;
        width: 100%;
        height: fit-content;
        border-radius: 1rem 1rem 0 0;
    }
    .hoverComponent-left li{
        width: 100%;
        border-bottom: none;
        border-right: 1px dotted black;
        font-size: 0.8rem;
        padding: 0.5rem;
    }
    .hoverComponent-right{
        width: 100%;
        padding: none;
        border-radius: 1rem 0 1rem 0;

    }
}


@media screen and (max-width: 360px) {
    .hoverComponent-left li{
        padding: 0.2rem;
        }
}