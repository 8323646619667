/* v-cards */

.v-card {
  display: flex;
  flex-direction: column-reverse;
  max-width: 320px;
  box-shadow: 0px 5px 4px rgba(221, 221, 221, 0.25);
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  overflow: hidden;
}

.card_container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}
.card_wrapper {
  display: flex;
  justify-content: center;
}
.v-card img {
  width: 100%;
  height: 100%;
}
.card_content {
  padding: 1rem;
}
.card_content h2 {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 1rem;
}
.card_content p {
  font-size: 1.5rem;
  color: #2c2c2c;
  text-align: justify;
}
.status > p {
  margin-top: 1rem;
  color: grey;
  margin-bottom: 1rem;
}

/* ======================================================== Card2 ============================================ */

.card2-item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  width: 85%;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 10px;
  direction: ltr;
  /* background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); */
  padding: 1rem;
}
.card2-info {
  padding: 10px;
  flex-basis: 60%;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: black;
}
.card2-info h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 3rem;
  font-weight: 500;
}
.card2-info > h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 10px;
}
.card2-info p {
  font-size: 1.5rem;
  color: #747474;
  text-align: justify;
}
.card2-img {
  flex-basis: 40%;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
}

/* .card2-img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #2c313675 0%, #485563ab 100%);
  opacity: 0.7;
} */
/* .card2-img:after:hover {
  box-shadow: 0 0 10rem rgb(68, 68, 68);
} */
.card2-img img {
  width: 100%;
  border-radius: 1rem;

  transition: 0.3s ease transform;
}
.card2-item:hover {
  box-shadow: 0 0 0.3rem rgba(68, 68, 68, 0.658);
  transition: all 0.1s ease-in-out;
}

@media screen and (max-width:700px) {
  .card2-item{
    flex-direction: column-reverse;
    width: 100%;
  }
  
}
/* =============================== CARD 3 ============================================ */
.card3 {
  position: relative;
  width: 250px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 0 0 5px #9797975e;
  transition: 0.2s ease-in-out;
  margin: 0 auto;
}
.card3:hover {
  transform: scale(1.01);
  box-shadow: 0 0 5px #5353534d;
  transition: 0.2s ease-in-out;
}
.card3-content {
  position: relative;
  top: 0;
  padding: 20px;
}
.card3-image-container img {
  border-radius: 5px;
  width: 100%;
}
.card3-content h3 {
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
  font-size: 2rem;
}
.card3-content p {
  font-size: 1.3rem;
}

/*  ============================================= AlimniCard Start ================================================= */
.alumnicard-container {
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-right: 1rem;
}
.alumnicard-top {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.alumnicard-top img {
  width: 30%;
  border-radius: 50%;
}
.alumnicard-bottom {
  border-radius: 50%;
  height: 100px;
}
/*  ============================================= AlimniCard End ================================================= */
/*  ============================================= TechCard Start ================================================= */
.tech-card-container {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem;
}
.tech-card-container svg {
  font-size: 5rem;
}
.tech-card-container h1 {
  text-align: center;
}
/*  ============================================= TechCard End ================================================= */
/*  ============================================= TitleTextCard Start ================================================= */
.titletext-card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 200px;
  padding: 1rem;
  border-radius: 0.5rem;
}
.titletext-card-container > h1 {
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  font-size: 1.5rem;
  height: 50px;
}
.titletext-card-container > p {
  text-align: center;
  font-size: 1.2rem;
  color: #747474;
}
/*  ============================================= TitleTextCard End ================================================= */
/*  ============================================= IconTextCard Start ================================================= */

.icon-text-card {
  position: relative;
  width: 350px;
  height: 400px;
  padding: 2rem;
  box-shadow: 0 0 2px rgb(155, 155, 155);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.icon-text-card:hover {
  background-color: #00a2ff;
  color: #fff;
  transition: all 0.3s linear;
}
.icon-text-card:hover svg,
.icon-text-card:hover p,
.icon-text-card:hover .heading {
  color: #fff;
  transition: all 0.3s linear;
}
.icon-text-card svg {
  font-size: 3rem;
  color: #ffc400;
}
.icon-text-card .heading {
  font-size: 2rem;
  font-family: "Josefin Sans", sans-serif;
  color: black;
}
.icon-text-card p {
  font-size: 1.7rem;
  color: #5c5c5c;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

/*  ============================================= IconTextCard End ================================================= */
/*  ============================================= TechStackCard Start ================================================= */
.tech-stack-card {
  position: relative;
  width: 350px;
  height: 200px;
  padding: 2rem;
  box-shadow: 0 0 2px rgb(155, 155, 155);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.tech-stack-card .head {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.tech-stack-card .head svg {
  font-size: 3rem;
  color: #ffc400;
}
.tech-stack-card .head h3 {
  font-size: 3rem;
  color: black;
  margin-left: 2rem;
}

.tech-stack-card ul{
display: flex;
flex-wrap: wrap;
gap: 2rem;
justify-content: space-between;
align-items: center;
}
.tech-stack-card ul li{
  list-style-type: disc;
  font-size: 1.7rem;
  font-family: "Josefin Sans", sans-serif;
  margin-left: 2rem;

}
/*  ============================================= TechStackCard End ================================================= */
