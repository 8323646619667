@import "https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&display=swap');

* {
  font-family: "Montserrat", sans-serif;
}
:root {
  --direction: ltr;
  --direction-alt: rtl;
  --space-xs: 0.5rem;
  --space-sm: 1rem;
  --space-md: 2rem;
  --space-lg: 6rem;
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color to match images */
  /*Green dark 190 - Green 171 - Grren Blue 200*/
  --hue-color: 190;

  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 64%, 22%);
  --first-color-second: hsl(var(--hue-color), 64%, 22%);
  --first-color-alt: hsl(var(--hue-color), 64%, 15%);
  --title-color: hsl(var(--hue-color), 64%, 18%);
  --text-color: hsl(var(--hue-color), 24%, 35%);
  --text-color-light: hsl(var(--hue-color), 8%, 60%);
  --input-color: hsl(var(--hue-color), 24%, 97%);
  --body-color: hsl(var(--hue-color), 100%, 99%);
  --white-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 75%);

  /*========== Font and typography ==========*/
  --body-font: "Open Sans", sans-serif;
  --title-font: "Raleway", sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Hover overlay ==========*/
  --img-transition: 0.3s;
  --img-hidden: hidden;
  --img-scale: scale(1.1);
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --first-color-second: hsl(var(--hue-color), 54%, 12%);
  --title-color: hsl(var(--hue-color), 24%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 29%, 12%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}
body {
  /* font-family: "Palatino", serif; */

  /* color: #fff; */
  background-color: #fff;
  line-height: 1.5;
}
html {
  scroll-behavior:smooth;
}

/* .section {
  margin: 2rem 0;
} */


.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  place-items: center;
  gap: 1rem;
}



.section-title {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 4rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
  /* text-transform: uppercase; */
  letter-spacing: 0.2rem;
  text-align: center;
}
.section-title span {
  font-family: 'Josefin Sans', sans-serif;
  color: crimson;
}



#home-top {
  background-image: url(../../../public/assets/service/test.jpg);
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
  height: 50rem;
  display: flex;
  align-items: center;
}
#home-top::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}

#home-top .home-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-top {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 90%;
  padding: 2rem 3rem 0 3rem;
  margin: 0 auto;
  border-radius: 1rem;
}
.home-top .home-top-left {
  width: 50%;
}
.home-top .home-top-left h1 {
  font-family: "Josefin Sans", sans-serif;

  color: white;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.home-top .home-top-left h3 {
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;

}
.home-top .home-top-left .cta {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: #ff6a6a;
  text-transform: capitalize;
}
.home-top .home-top-left .cta:hover {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: transparent;
}
.home-top .home-top-right {
  width: 50%;
}
.home-top .home-top-right video {
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0 0 1rem white;
}



/* front intro Start */

.front-intro {
  width: 85%;
  padding: 2rem;
  margin: 3rem auto;
  box-shadow: 0 0 0.2rem #4b4a4a;
  border-radius: 1rem;
}
/* front intro End */

/* home-services section */
#home-services .home-services {
  flex-direction: column;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 0;
}
#home-services .home-services-header h1 {
  margin-bottom: 50px;
}
#home-services .all-home-services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.article {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  gap: var(--space-md);

}

/* Alternate direction */
.article:nth-child(even) {
  direction: var(--direction-alt);
}

/* Revert direction on child elements */
.article > * {
  direction: var(--direction);
}

/* Other page styling */

.header {
  margin-block-end: var(--space-lg);
  padding-block-start: var(--space-sm);
  padding-block-end: var(--space-sm);
  background: papayawhip;
  font-size: 1rem;
}

.main {
  margin-top: 5rem;
}

.main > * + * {
  margin-block-start: var(--space-lg);
  margin-block-end: var(--space-lg);
}

.content > * + * {
  margin-block-start: var(--space-sm);
}
.content h1 {
  font-family: 'Josefin Sans', sans-serif;

  font-size: 3rem;
  font-weight: 500;
}
.content p {
  font-size: 1.5rem;
  color: #747474;
  text-align: justify;
}
.headline {
    font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 3.5rem;
  /* font-size: clamp(1.75rem, 4vw, 2.5rem); */
  line-height: 1.25;
  letter-spacing: -0.01em;
}

.image-wrap {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  inline-size: 100%;
  border-radius: 1rem;
}

.image-wrap img {
  position: absolute;
  object-fit: fill;
  inline-size: 100%;
}

@supports not (aspect-ratio: 16 / 9) {
  .image-wrap::before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }

  .image-wrap::after {
    display: block;
    content: "";
    clear: both;
  }
}

/* End home-services section */

/* Start Insights section */
.insights-section {
  margin-top: 50px;
}
.insights-container {
  gap: 3rem;
  min-height: 300px;
  padding: 3rem;
  border-radius: 1rem;
}
.insight-info {
  width: 30%;
}
.heading2 {
  font-family: 'Josefin Sans', sans-serif;

  font-size: 3rem;
  font-weight: 400;
  color: black;
}
.paragraph {
  font-size: 1.5rem;
  color: #5e5e5e;
}

.insight-imgs {
  width: 75%;
display: flex;
  gap: 20px;

}
.insight-imgs img {
  width: 33%;
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.2s ease-in-out;
}

.card img {
  width: 270px;
  height: 300px;
  object-fit:fill;
}
.card:hover {
  overflow: hidden;
}
.card:hover img {
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
  /* opacity: 0.8; */
}
.card:hover .card_content {
  background: #ffffff;
  transform: translateY(0px);
}

.card_content {
  padding: 1rem;
  position: relative;
  top: -4.5rem;
  margin-bottom: -6.5rem;
  transition: 0.2s ease-in-out;
  transform: translateY(100px);
}
.card_content h3 {
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
}
/* End Insights section */

/* Start Our Service Areas section */
.service-areas {
  margin-top: 2rem;
  margin: 0 auto;
  width: 85%;
}

.service-areas .course-item{
  position: relative;
  background-size: cover;
}
.service-areas .course-item::after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.3;
  border-radius: 1rem;
}
.service-areas .course-item p{
z-index: 10;
}
.service-areas .item1{
  background-image: url(../../../public/assets/areas/health.jpeg);
}
.service-areas .item2{
  background-image: url(../../../public/assets/areas/ecom.jpeg);
}
.service-areas .item3{
  background-image: url(../../../public/assets/areas/edu.jpeg);
}
.service-areas .item4{
  background-image: url(../../../public/assets/areas/realestate.jpeg);
}
.service-areas .item5{
  background-image: url(../../../public/assets/areas/media.jpeg);
}
.service-areas .item6{
  background-image: url(../../../public/assets/areas/logistics.jpg);
}
.service-areas .item7{
  background-image: url(../../../public/assets/areas/logistics.jpg);
}
.service-areas .item8{
  background-image: url(../../../public/assets/areas/logistics.jpg);
}


/* Start Inside ICOESS section */
.inside-icoess{
  width: 100%;
}
.work {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 50px auto;
}
.grid_item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inside-icoess-card {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 10px;
  box-shadow: 3px 5px 5px rgba(1, 1, 1, 0.2);
  transition: 0.2s ease-in-out;
}
.inside-icoess-card:hover {
  transform: scale(1.04);
  box-shadow: 3px 5px 5px rgba(1, 1, 1, 0.3);
  transition: 0.2s ease-in-out;
}
.inside-icoess-card_content {
  position: absolute;
  bottom: 0;
  padding: 20px;
}
.image_container img {
  width: 100%;
}
.inside-icoess-card_content h3 {
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 10px;
}
.inside-icoess-card_content p {
  font-size: 1.2rem;
}
/* End Inside ICOESS section */


.home-about{
  display: flex;
  flex-direction: row;
  width: 80%;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 1rem;
}
.home-about-left{
  width: 50%;
}
.home-about-left h1{
  font-family: 'Josefin Sans', sans-serif;

  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.home-about-left h3{
  font-family: 'Josefin Sans', sans-serif;

  font-size: 2.5rem;
  font-weight: 600;
}
.home-about-right{
  width: 50%;
  border-radius: 1rem;
}

/* ================================== Responsiveness ===================================== */

@media screen and (max-width: 500px) {
  .cardslider{
    flex-direction: column;
  }
  .course-item{
    height: 120px;
    width: 120px;
  }
}

@media screen and (max-width: 870px) {
  #home-top{
    height: 60rem;
  }
  #home-top .home-top {
    flex-direction: column-reverse;

  }
  .home-top .home-top-left {
    width: 100%;
  }
  .home-top .home-top-left h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .home-top .home-top-left h3 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .home-top .home-top-right {
    width: 100%;
  }
  .inside-icoess-card {
    height: 400px;
  }
}
@media screen and (max-width: 700px) {
  .insights-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .insights-container .row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .insight-info {
    width: 100%;
  }
  .insight-imgs,
  .insight-imgs img {
    width: 100%;
  
  }
  .inside-icoess-card {
    height: 350px;
  }
  .contact-card {
    flex-direction: column;
  }
  
  .contact-left {
    width: 60vw;
    height: 40vh;
  }
}
@media screen and (max-width: 400px) {
  .insight-imgs {
    width: 100%;
  }
  .insights-container{
    align-items: start;
  }
  .service-areas .column{
    flex-direction: row;
  }
}

@media screen and (max-width: 350px) {
  .contact-container {
    overflow: hidden;
  }
  .contact-left {
    height: 20vh;
  }
  .contact {
    flex-direction: column;
  }
}
