
/* IT Talent Container Starts */
.text-component-container{
    display: flex;
    align-items: center;
  }
  .text-component-container p{
    font-family: "Josefin Sans", sans-serif;
  color: black;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  }
  /* IT Talent Container End */