@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.desktop-none {
  display: none;
}

/* SideNav Start*/
.sidenav {
  position: fixed;
  left: 0;
  top: 30%;
  height: 50vh;
  backdrop-filter: blur(30px);
  background-color: #ffffffa0;
  /* bottom: 10%; */
  border-radius: 0 1rem 1rem 0;
  z-index: 99;
}
.sidenav ul {
  list-style-type: none;
  border-radius: 0 1rem 1rem 0;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 0.5rem rgb(54, 54, 54);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidenav li {
  height: 100%;
  display: flex;
  align-items: center;
}

.sidenav li a {
  width: 100%;
  font-family: "Josefin Sans", sans-serif;

  display: flex;
  align-items: center;
  color: #000000;
  padding: 8px 2px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.4rem;
  height: 100%;

  background-color: #ffffff00;
}

.sidenav li a:hover {
  background-color: #4caf50;
  color: white;
}

/* SideNav End */

/* navbar style start  */

.main-nav {
  position: sticky;
  top: 0;
  backdrop-filter: blur(40px);
  background-color: #ffffffd8;
  height: 10rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  z-index: 99;
}

.main-nav ul {
  gap: 1rem;
  display: flex;
}

.logo {
  display: grid;
  /* background-color: #3b5998; */
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
}

.logo img {
  width: 5rem;
}
.menu-link {
  grid-column: 3/4;
}

.menu-link > ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media ul {
  height: 10rem;
  display: flex;
  align-items: center;
}

.social-media ul li {
  text-align: right;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link > ul li {
  font-size: 1.8rem;
  position: relative;
  padding: auto 0;
}

.menu-link > ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  font-family: "Josefin Sans", sans-serif;
}
/* .menu-link ul li a:focus {
  text-transform: capitalize;
  color: rgb(255, 94, 0);
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: dotted;
  transition: 0.5s;
} */
.menu-link > ul li a::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

.menu-link > ul li a:hover::after {
  width: 100%;
}

.social-media > ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #e721bc;
}

.youtube {
  color: rgb(255, 0, 0);
}

.menu-link > ul li:hover .nav-services-container li {
  display: block;
}
.menu-link > ul li:hover .nav-services-container {
  position: absolute;
  top: 2rem;
  left: -5rem;
  padding: 2rem;
  border-radius: 1rem;
  backdrop-filter: blur(40px);
  background-color: #ffffffd8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 300px !important;
  z-index: 100;
}
.nav-services-container li {
  display: none;
  position: absolute;
  top: 0px;
}

.nav-services {
  position: relative;
}

.nav-services-container li {
  width: 200px;
  font-size: 1.8rem;
  position: relative;
  top: 0;
  padding: auto 0;
}

.nav-services-container li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  text-align: start;
  display: block;
  font-size: 1.3rem;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    padding: 1rem;
  }

  .logo,
  .menu-link > ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .mobile-none {
    display: none;
  }

  .logo > img {
    width: 4rem;
  }
  .main-nav {
    position: relative;
    height: 7rem;
    /* grid-template-columns: 2rem 2fr 3fr 2rem 2rem; */
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media > ul {
    height: 7rem;
  }

  .mobile-menu-link {
    position: absolute;
    top: 6rem;
    left: 0;
    background-color: rgb(255, 255, 255);
    height: 600px;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    transition: all 1s linear;
    box-shadow: 0 3px 5px #818181;
    z-index: 99;
  }

  .mobile-menu-link > ul {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .mobile-menu-link > ul li:hover .nav-services-container li {
    display: block;
  }
  .mobile-menu-link > ul li:hover .nav-services-container {
    /* display: block; */
    position: relative;
    left: 0;
  }

  .menu-link > ul li:hover .nav-services-container {
    position: relative;
    top: 0;
    left: 0;
    padding: 2rem;
    border-radius: 1rem;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    z-index: 100;
  }
  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: inline;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .social-media .hamburger-menu a svg {
    font-size: 3rem;
  }
  .sidenav {
    display: none;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
