/* Start Contact section */

.contact-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
  }
  
  .contact-card {
    width: 100%;
    height: auto;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid white;
    display: flex;
    gap: 2rem;
  }
  
  .contact-left {
    width: 30%;
    height:max-content;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .contact-left img {
    width: 50%;
    object-fit: cover;
  }
  .contact-left p {
    margin-top: 2rem;
    width: 50%;
    color: rgb(0, 198, 212);
    font-style: italic;
    font-size: 1.7rem;
    text-align: justify;
  }
  
  .contact-right{
    width: 70%;
    padding-right: 10%;
  }
  
  .contact-form form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  
  .contact-form form label {
    display: block;
    font-size: 1.5rem;
  }
  
  .contact-form form p {
    margin: 0;
    padding: 1em;
  }
  
  .contact-form form .block {
    grid-column: 1 / 3;
  }
  
  .contact-form form button,
  .contact-form form input,
  .contact-form form textarea,
  .contact-form form select{
    width: 100%;
    padding: .7em;
    border: none;
    background: none;
    outline: 0;
    border: 1px solid #181818;
  }

  
  .contact-form form button {
    background: #e4e4e4;
    border: 0;
      width: fit-content;
      padding: 1.5rem 4rem;
      font-size: 1.8rem; 
  }
  
  .contact-form form button:hover,
  .contact-form form button:focus {
    background-color: #999999;
    color: #fff;
    outline: 0;
    cursor: pointer;
  }

  /* ========================== Contact Section Start ========================================= */
  
.contact-section{
  background-color: #963596;
  display: flex;
  flex-direction: row;
  gap: 5rem;
  padding: 6rem;
}

.contact-section-left{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-section-left img{
  width: 70%;
  border-radius: 1rem;
}
.contact-section-left h1{
  font-family: 'Josefin Sans', sans-serif;

  font-size: 3rem;
  font-weight: 600;

}
.contact-section-right{
  width: 50%;
  border-radius: 1rem;
}
.contact-section-right h1{
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 4rem;
}
.contact-section-right .cta{
  background-color: black;
  color: #fff;
  padding: 1.3rem 5rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  margin-top: 2rem;
  border: 1px solid black;

}
.contact-section-right .cta:hover{
  background-color: transparent;
  color: #000000;
  border: 1px solid black;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width:700px) {
  .contact-section{
    flex-direction: column;
    gap: 1rem;
    padding: 3rem;
  }
  .contact-section-left{
    width: 100%;
  }
  .contact-section-left img{
    width: 100%;
  }
  .contact-section-right{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .contact-section-right h1{
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
 }
}
  /* ========================== Contact Section End ========================================= */
  /* ========================== ApplyJob Section Start ========================================= */
  @import url(https://fonts.googleapis.com/css?family=Roboto:300);

.apply-job {
  max-width: 800px;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input,
.form textarea,
.form select
 {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 15px 0 0 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #00d6de;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-top: 15px;
}
.form button:hover,.form button:active,.form button:focus {
  background: #00b6bc;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4CAF50;
  text-decoration: none;
}
.form .register-form {
  display: none;
}

.input-error{
  color: #f13a3a;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
}

.input-error-border{
  border: 1px solid #c51244 !important;
}
  /* ========================== ApplyJob Section End ========================================= */

  /* ========================== ApplyCourseSmall Section Start ========================================= */


  .contact-card {
    width: 40rem;
    height: fit-content;
    padding: 5rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: #1f1f1f;
    align-items: center;
  }
  
  .contact-card .upper {
    margin-bottom: 4rem;}
  .contact-card .column {
    gap: 0;
  }
  .contact-card .btn2 {
    margin-top: 1rem;
  
  }
  .contact-card .heading {
    font-family: "Source Serif 4", serif;
    font-weight: 400;
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 3rem;
  }
  .contact-card .heading2 {
    font-weight: 400;
    font-size: 2.5rem;
    color: #ffffff;
    text-align: center;
  }
  .contact-card p {
    font-size: 1.3rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 0.7rem;
    letter-spacing: 0.1rem;
  }
  .contact-card .para2 {
    font-size: 1.5rem;
    color: #bbbbbb;
    text-align: center;
    margin-bottom: 0.7rem;
    letter-spacing: 0.1rem;
  }
  .contact-card .btn2 {
    font-size: 1.3rem;
  }
  
  .contact-form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }
  .contact-form .cta{
background-color: crimson;
color: white;
  }
  .contact-form input,
  .contact-form select,
  .contact-form option {
  background-color: #000000;
  color: rgb(204, 204, 204);
  width: 100%;
  font-size: 1.3rem;
  padding: 1.1rem;
  font-weight: 600;
  outline: none;
  border: none;
  }
  .bottom-line {
    position: relative;
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .bottom-line::after {
    content: "";
    width: 100%;
    height: 3px;
    background: #ffb453af;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
    z-index: 1;
  }
  
  @media screen and (max-width:500px) {
    .contact-card {
      width: 90%;
      height: 55rem;
      margin: 0 auto;
    }
    .contact-card .heading{
      font-size: 1.8rem;
    }
    .contact-card .heading2{
      font-size: 1.8rem;
    }
  }

    /* ========================== ApplyCourseSmall Section End ========================================= */

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
*
{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}
.contact 
{
	position: relative;
	min-height: 100vh;
	padding: 50px 100px;
	display: flex;
  background-color: rgb(245, 245, 245);
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.contact .content 
{
	max-width: 800px;
	text-align: center;
}
.contact .content h2 
{
	font-size: 5rem;
	font-weight: 500;
}
.contact .content p 
{
	font-size: 1.5rem;
	font-weight: 300;
}
.contact-page-container 
{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
	margin-top: 30px;
}
.contact-page-container .contactInfo 
{
	width: 50%;
	display: flex;
	flex-direction: column;
}
.contact-page-container .contactInfo .box 
{
	position: relative;
	padding: 20px 0;
	display: flex;
	cursor: pointer;
}
.contact-page-container .contactInfo .box .icon 
{
	min-width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2.5rem;
	transition: 0.5s;
}
.contact-page-container .contactInfo .box:hover .icon 
{
	background: #00bcd4;
  color: #fff;
}
.contact-page-container .contactInfo .box .text 
{
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	font-size: 2rem;
	font-weight: 300;
}
.contact-page-container .contactInfo .box .text h3 
{
	font-weight: 500;
	color: #00bcd4;
}
.contact-page-container .contactInfo .txt
{
	margin-top: 50px;
	font-weight: 500;
	padding-left: 10px;
	border-left: 50px solid #e91e63;
	line-height: 1em;
}
.sci 
{
	position: relative;
	display: flex;
	gap: 30px;
	margin: 20px 0;
}
.sci li 
{
	list-style: none;
}
.sci li a 
{
	font-size: 2.5rem;
	transition: 0.5s;
}
.sci li a:hover 
{
	color: #00bcd4;
}


.contact-page-container .contactForm
{
	position: relative;
	width: 40%;
	background: #fff;
	min-height: 100px;
	padding: 60px;
} 
.contact-page-container .contactForm h2 
{
	font-size: 3rem;
	color: #333;
	font-weight: 500;
}
.contact-page-container .contactForm .inputBox 
{
	position: relative;
	width: 100%;
	margin-top: 20px;
}
.contact-page-container .contactForm .inputBox input, 
.contact-page-container .contactForm .inputBox textarea 
{
	width: 100%;
	padding: 5px 0;
	font-size: 1.5rem;
	margin: 10px 0;
	border: none;
	border-bottom: 2px solid #333;
  /* box-shadow: 0 0 5pc #dfdfdf; */
  /* background-color: #f7f7f7; */
	outline: none;
	resize: none;
}
.contact-page-container .contactForm .inputBox span 
{
	position: absolute;
	left: 0;
	pointer-events: none;
	padding: 5px 0;
	margin: 10px 0;
	font-size: 1.5rem;
	color: #666;
	transition: 0.5s;
}
.contact-page-container .contactForm .inputBox input:focus ~ span, 
.contact-page-container .contactForm .inputBox textarea:focus ~ span,
.contact-page-container .contactForm .inputBox input:valid ~ span, 
.contact-page-container .contactForm .inputBox textarea:valid ~ span 
{
	color: #e91e63;
	font-size: 1.5rem;
	transform: translateY(-25px);
}
.contact-page-container .contactForm .inputBox input[type="submit"]
{
	width: 100px;
	background: #00bcd4;
	color: #fff;
	border: none;
	cursor: pointer;
	padding: 10px;
	font-size: 1.5rem;
	font-weight: 500;
}

/* lets make it responsive */
@media (max-width: 991px)
{
	.contact
	{
		padding: 50px;
	}
	.contact-page-container
	{
		flex-direction: column;
	}
	.contact-page-container .contactInfo,
	.contact-page-container .contactForm
	{
		width: 100%;
	}
	.contact-page-container .contactForm
	{
		padding: 50px 30px;
	}
}