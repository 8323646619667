.career-top-section {
    background-image: url(../../../public/assets/career/team2.jpg);
    background-size: cover;
    background-position: top center;
    position: relative;
    z-index: 1;
    height: 70rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
  }
  .career-top-section::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
  }

  .career-top-left {
    width: 50%;
  }
  .career-top-left h1 {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
 .career-top-left p {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
  }
.career-top-left>h3 {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .career-top-left .cta {
    color: white;
  width: fit-content;
  font-size: 2rem;
  text-transform: capitalize;
  background-color: crimson;
  }
  .career-top-left .cta:hover {
  color: white;
  width: fit-content;
  font-size: 2rem;
  text-transform: capitalize;
  background-color: transparent;
  }

  .career-top-right{
    width: 50%;
  }

.career-oppotunities svg{
transform: scale(2);
}
.career-page .cta{
    margin-top: 0;
    position: absolute;
    right: 5rem;
    bottom: 0;
}


.job-description h1{
margin-bottom: 1rem;
}
.job-description ul{
display: flex;
flex-direction: column;
gap: 1rem;
margin-left: 2rem;
margin-bottom: 5rem;
}

.job-description ul li{
    list-style-type: disc;
    font-size: 1.5rem;
}
.job-description a{
    padding: 2rem;
}
.job-description button{
    position: relative;
    bottom: 0;
    left: 0; 
    width: fit-content;
    font-size: 1.5rem;
    margin-top: 2rem;
}

@media screen and (max-width:700px) {
    .career-page .row{
        flex-direction: column;
    }
    .career-oppotunities h3{
        width: 70%;
    }
}