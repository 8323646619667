@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700;800&family=Rosarivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;500;600;700;800&family=Rosarivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500;600&display=swap');
p{
  /* font-family: 'Rosarivo', serif; */
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.wh-150 {
    width: 150px !important;
    height: 150px !important;
  }
  .wh-300 {
    width: 300px !important;
    height: 300px !important;
  }
  
  .w-85{
    width: 87vw;
  }
  
  .bg-green {
    background-color: #45e645ee;
  }
  .bg-cyan {
    background-color: #1de7ccc5;
  }
  .bg-leafgreen {
    background-color: rgb(147, 189, 33);
  }
  .bg-green {
    background-color: #45e645ee;
  }
  .bg-grey {
    background-color: #666666;
  }

  .c-skyblue{
    color: skyblue;
  }
  
  
  .flex {
    display: flex;
  }
  
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .jcc {
    display: flex;
    justify-content: center;
  }
  .jcsb {
    display: flex;
    justify-content: space-between;
  }
  .jcse {
    display: flex;
    justify-content: space-evenly;
  }
  
  .aic {
    display: flex;
    align-items: center;
  }
  
  .cta {
    font-family: 'Playpen Sans', cursive;
    display: inline-block;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    background-color: transparent;
    border: 1px solid crimson;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-top: 30px;
    transition: 0.3s ease;
    transition-property: background-color, color;
  }
  .cta:hover {
    color: white;
    background-color: #ff6a6a;
    cursor: pointer;
  }

  .grid {
    display: grid;
    gap: 1.5rem;
  }
  .container {
    padding: 10px 5%;
  }

  .section {
    padding: 3rem 0;
  }
  .heading1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 3.5rem;
    color: #494949;
    font-weight: 600;
    text-align: start;
    margin: 2rem 0;
  }
  .heading2 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 3rem;
    color: #494949;
    font-weight: 600;
    text-align: start;
    margin: 2rem 0;
  }
  .heading3 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 2.5rem;
    color: #494949;
    font-weight: 600;
    text-align: center;
    margin: 2rem 0;
  }
  .heading4 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 2rem;
    color: #838383;
    font-weight: normal;
    text-align: center;
    margin: 2rem 0;
  }

  .heading5{
    font-size: 1.4rem;
    font-weight: 700;
  }

  .para1{
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    color: #3d3d3d;
    font-weight: normal;
    margin: 1rem 0;
  }


  /*  =============== margins ===================================== */

  .mtb-3{
    margin: 3rem 0;
  }

  .mb-4{
    margin-bottom: 4rem;
  }

  .br3-orange{
    border-right: 3px solid #f26005;
  }